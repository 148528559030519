<template>
  <div class="report winStyle" style="overflow: hidden">
    <!-- 报表弹框 -->
    <div class="reportWin box box_column" v-if="win == 1">
      <div class="close pointer" @click="closeBtn"></div>
      <div class="winB"></div>
      <div class="titleName">历史报表</div>
      <ul class="flex1 reportBox">
        <!-- <div class="report-item" v-for="(item, index) in reportData" :key="index" @click="getInfor(item)">
          <p class="fs14">{{ item.reportName }}</p>
          <p class="fs12" style="color: #2772f0">{{ item.createTime }}</p>
        </div> -->
        <li v-for="(item, index) in reportData" :key="index" @click="getInfor(item)">
          <p class="fs14">{{ item.reportName }}</p>
          <p class="fs12" style="color: #2772f0">{{ item.createTime }}</p>
        </li>
      </ul>
      <div style="z-index: 3">
        <common-pagination :total="total" :currentPage="currentPage" :pageSize="20" @handleCurrentChange="handleCurrentChange" style="margin-top: 20px" />
      </div>
    </div>

    <div class="main box box_column">
      <div class="line1 box-bottom-border">
        <!-- <div class="top">统计报表</div> -->
        <div class="bottom box center bottom1 justify noborder">
          <div class="box center">
            <!-- 时间选择 -->
            <div class="box center">
              <ul class="box center ulTab">
                <li v-for="(item, index) in nav" :key="index" class="box center" @click="getTab(item)">
                  <div style="width: 100%" class="fu">
                    <div>{{ item.name }}</div>
                    <div class="tiao" v-if="active == item.id"></div>
                  </div>
                  <div class="shu" v-if="index != nav.length - 1"></div>
                </li>
              </ul>
              <div class="timeUi btnFu" style="margin-left: 60px" v-if="active == 1">
                <div class="leftBtn" @click="getTime(1)"></div>
                <div class="rightBtn" @click="getTime(-1)"></div>
                <el-date-picker @change="changeTime" v-model="time" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss">> </el-date-picker>
              </div>
              <div class="timeUi btnFu" style="margin-left: 60px" v-if="active == 2">
                <div class="leftBtn" @click="getTimeYue('1')"></div>
                <div class="rightBtn" @click="getTimeYue('-1')"></div>
                <el-date-picker @change="changeTimeYue" v-model="yuetime" type="month" placeholder="选择月" format="yyyy-MM" value-format="yyyy-MM-dd HH:mm:ss"> > </el-date-picker>
              </div>
            </div>
            <!-- 报表模板 -->
            <ul class="box center ulTab mL20" v-if="active == 1">
              <li v-for="(item, index) in Template" :key="index" class="box center" @click="getTemp(item.id)">
                <div style="width: 100%" class="fu">
                  <div>{{ item.name }}</div>
                  <div class="tiao" v-if="tempActive == item.id"></div>
                </div>
                <div class="shu" v-if="index != Template.length - 1"></div>
              </li>
            </ul>

            <ul class="box center ulTab mL20" v-if="active == 2">
              <li v-for="(item, index) in Template1" :key="index" class="box center" @click="getTemp(item.id)">
                <div style="width: 100%" class="fu">
                  <div>{{ item.name }}</div>
                  <div class="tiao" v-if="tempActive == item.id"></div>
                </div>
                <div class="shu" v-if="index != Template1.length - 1"></div>
              </li>
            </ul>

            <!-- 生成报表 -->
            <div class="box center">
              <!-- <div class="use useSite" @click="lookReport">生成报表</div> -->
              <div class="history box around">
                <div class="box center" @click="getWin">
                  <div style="margin-right: 8px">查看历史报表</div>
                  <img src="../../assets/images/report/Combined Shape.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <!-- 导出 -->
          <div class="box center" style="margin-right: 20px;" v-if="tempActive != 1">
            <div class="btnStyle pointer" @click="xiazai" :class="!myOption ? 'disable_icon' : ''">报表导出</div>
            <div class="btnStyle pointer" @click="PrintRow" :class="!myOption ? 'disable_icon' : ''">报表打印</div>
          </div>
          <div class="box center" style="margin-right: 20px;"  v-if="active == 2 && tempActive == 1">
            <div class="btnStyle pointer" @click="xiazai1" :class="!myOption ? 'disable_icon' : ''">报表导出</div>
            <!-- <div class="btnStyle pointer" @click="printData">报表打印</div> -->
          </div>
        </div>
      </div>
      <div class="noDataMain" v-if="noDataState">
        <img src="../../assets/images/noData.png" alt="" />
        <span>暂无数据</span>
      </div>
      <div class="line4 flex1 box box_column recordImg" v-if="winBottom == 1 && !noDataState" ref="print">
        <!-- <div class="lineTop"></div> -->
        <div class="flex1 formBox box box_column">
          <div class="box center mB20">
            <img style="margin-right: 12px; width: 14px; height: 14px" src="@/assets/images/fragment-title-icon.png" />
            <div class="fs18 textC" style="margin-right: 28px">{{ reportName }}</div>
            <div class="fs18 textC">{{ reportTime }}</div>
          </div>

          <!-- 表格 1 -->
          <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm mB20">
            <div class="box center justify" style="width: 100%">
              <el-form-item label="" class="width20">
                <div class="box center">
                  <span class="noFlex sp">全部监测项数量</span>
                  <!-- <el-input class="flex1" v-model="ruleForm.zonglan1" placeholder="请输入" :disabled="true"></el-input> -->
                  <span class="flex1 zhw-content">{{ ruleForm.zonglan1 }}</span>
                </div>
              </el-form-item>
              <el-form-item label="" style="" class="width20">
                <div class="box center">
                  <span class="noFlex sp">作用监测项数量</span>
                  <!-- <el-input v-model="ruleForm.hezai1" placeholder="请输入" :disabled="true"></el-input> -->
                  <span class="flex1 zhw-content">{{ ruleForm.hezai1 }}</span>
                </div>
              </el-form-item>
              <el-form-item label="" class="width20">
                <div class="box center">
                  <span class="noFlex sp">结构响应项数量</span>
                  <!-- <el-input v-model="ruleForm.xiaoying1" placeholder="请输入" :disabled="true"></el-input> -->
                  <span class="flex1 zhw-content">{{ ruleForm.xiaoying1 }}</span>
                </div>
              </el-form-item>
              <el-form-item label="" style="" class="width20">
                <div class="box center">
                  <span class="noFlex sp">环境监测项数量 </span>
                  <!-- <el-input v-model="ruleForm.huanjing1" placeholder="请输入" :disabled="true"></el-input> -->
                  <span class="flex1 zhw-content">{{ ruleForm.huanjing1 }}</span>
                </div>
              </el-form-item>
              <el-form-item label="" style="" class="width20">
                <div class="box center">
                  <span class="noFlex sp">结构变化项数量 </span>
                  <!-- <el-input v-model="ruleForm.huanjing1" placeholder="请输入" :disabled="true"></el-input> -->
                  <span class="flex1 zhw-content">{{ ruleForm.bianhua1 }}</span>
                </div>
              </el-form-item>
            </div>
            <div class="box center justify" style="width: 100%">
              <el-form-item label="" class="width20">
                <div class="box center">
                  <span class="noFlex sp">全部监测点数量</span>
                  <!-- <el-input class="flex1" v-model="ruleForm.zonglan2" placeholder="请输入" :disabled="true"></el-input> -->
                  <span class="flex1 zhw-content">{{ ruleForm.zonglan2 }}</span>
                </div>
              </el-form-item>
              <el-form-item label="" style="" class="width20">
                <div class="box center">
                  <span class="noFlex sp">作用监测点数量 </span>
                  <!-- <el-input v-model="ruleForm.hezai2" placeholder="请输入" :disabled="true"></el-input> -->
                  <span class="flex1 zhw-content">{{ ruleForm.hezai2 }}</span>
                </div>
              </el-form-item>
              <el-form-item label="" class="width20">
                <div class="box center">
                  <span class="noFlex sp">结构响应点数量</span>
                  <!-- <el-input v-model="ruleForm.xiaoying2" placeholder="请输入" :disabled="true"></el-input> -->
                  <span class="flex1 zhw-content">{{ ruleForm.xiaoying2 }}</span>
                </div>
              </el-form-item>
              <el-form-item label="" style="" class="width20">
                <div class="box center">
                  <span class="noFlex sp">环境监测点数量</span>
                  <!-- <el-input v-model="ruleForm.huanjing2" placeholder="请输入" :disabled="true"></el-input> -->
                  <span class="flex1 zhw-content">{{ ruleForm.huanjing2 }}</span>
                </div>
              </el-form-item>
              <el-form-item label="" style="" class="width20">
                <div class="box center">
                  <span class="noFlex sp">结构变化点数量</span>
                  <!-- <el-input v-model="ruleForm.huanjing2" placeholder="请输入" :disabled="true"></el-input> -->
                  <span class="flex1 zhw-content">{{ ruleForm.bianhua2 }}</span>
                </div>
              </el-form-item>
            </div>
          </el-form>
          <!-- 表格 2 -->
          <div style="width: 100%; height: 100%">
            <!-- <div class="box center  justify" style="width:100%">
                        <div class="noLeft">1</div>
                        <div class="noLeft">2</div>
                        <div class="noLeft">3</div>
                        <div class="noLeft">4</div>
                        <div class="noLeft">5</div>
                        <div style="width:50%">
                            <div class="box">
                                <div style="width:80%">
                                    <div>1</div>
                                    <div class="box around">
                                        <div>1</div>
                                        <div>2</div>
                                        <div>3</div>
                                        <div>4</div>
                                    </div>
                                </div>
                                <div style="width:20%">
                                    <div>2</div>
                                    <div class="box around">
                                        <div>1</div>
                                        <div>2</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
            <!-- rowspan   rowspan -->

            <div v-if="!huanjing.dataS.length && !xiaoying.dataS.length && !hezai.dataS.length" style="height: 100%">
              <div class="box box_column flex-center">
                <img src="../../assets/images/report/empty-grey-icon.png" alt="" />
                暂无数据
              </div>
            </div>

            <table v-else class="table_1" border="1" style="border-collapse: collapse">
              <tr class="setP">
                <th rowspan="2" style="width: 7%">监测类型</th>
                <th rowspan="2" style="width: 7%">监测指标</th>
                <th rowspan="2" style="width: 6%">单位</th>
                <!-- <th rowspan="2">测点编号</th> -->
                <th rowspan="2" style="width: 10%">测点名称</th>
                <th colspan="4" style="width: 50%">实测数值</th>
                <th colspan="2" style="width: 20%">统计特征</th>
              </tr>
              <tr class="setP">
                <th style="width: 10%">最大值</th>
                <th style="width: 15%">发生时间</th>
                <th style="width: 10%">最小值</th>
                <th style="width: 15%">发生时间</th>
                <th style="width: 10%">平均值</th>
                <th style="width: 10%">方差</th>
              </tr>

              <!-- <tr class="setP" style="color:#909399;">
                <td style="text-align:center" colspan="10">暂无数据</td>
              </tr> -->

              <!-- 环境 -->
              <tr v-show="huanjing.dataS.length > 0">
                <td class="setbg1" :rowspan="huanjing.dataS.length + 1">环境监测</td>
              </tr>
              <tr v-for="item in huanjing.dataS" :key="item.id" class="setP setPLi" v-show="huanjing.dataS.length > 0">
                <td>{{ item.quotaName }}</td>
                <td>{{ item.unit }}</td>
                <!-- <td>{{item.targetId}}</td>    -->
                <td>{{ item.targetName }}</td>
                <td>{{ item.max }}</td>
                <td>{{ item.maxTime }}</td>
                <td>{{ item.min }}</td>
                <td>{{ item.minTime }}</td>
                <td>{{ item.mean }}</td>
                <td>{{ item.variance }}</td>
              </tr>

              <!-- 效应 -->
              <tr v-show="xiaoying.dataS.length > 0">
                <td class="setbg0" :rowspan="xiaoying.dataS.length + 1">结构响应</td>
              </tr>
              <tr v-for="item in xiaoying.dataS" :key="item.id" class="setP setPLi" v-show="xiaoying.dataS.length > 0">
                <td>{{ item.quotaName }}</td>
                <td>{{ item.unit }}</td>
                <!-- <td>{{item.targetId}}</td>    -->
                <td>{{ item.targetName }}</td>
                <td>{{ item.max }}</td>
                <td>{{ item.maxTime }}</td>
                <td>{{ item.min }}</td>
                <td>{{ item.minTime }}</td>
                <td>{{ item.mean }}</td>
                <td>{{ item.variance }}</td>
              </tr>

              <!-- 荷载 -->
              <tr v-show="hezai.dataS.length > 0">
                <td class="setbg1" :rowspan="hezai.dataS.length + 1">作用监测</td>
              </tr>
              <tr v-for="item in hezai.dataS" :key="item.id" class="setP setPLi" v-show="hezai.dataS.length > 0">
                <td>{{ item.quotaName }}</td>
                <td>{{ item.unit }}</td>
                <!-- <td>{{item.targetId}}</td>    -->
                <td>{{ item.targetName }}</td>
                <td>{{ item.max }}</td>
                <td>{{ item.maxTime }}</td>
                <td>{{ item.min }}</td>
                <td>{{ item.minTime }}</td>
                <td>{{ item.mean }}</td>
                <td>{{ item.variance }}</td>
              </tr>

              <!-- 变化 -->
              <tr v-show="bianhua.dataS.length > 0">
                <td class="setbg1" :rowspan="bianhua.dataS.length + 1">结构变化</td>
              </tr>
              <tr v-for="item in bianhua.dataS" :key="item.id" class="setP setPLi" v-show="hezai.dataS.length > 0">
                <td>{{ item.quotaName }}</td>
                <td>{{ item.unit }}</td>
                <!-- <td>{{item.targetId}}</td>    -->
                <td>{{ item.targetName }}</td>
                <td>{{ item.max }}</td>
                <td>{{ item.maxTime }}</td>
                <td>{{ item.min }}</td>
                <td>{{ item.minTime }}</td>
                <td>{{ item.mean }}</td>
                <td>{{ item.variance }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <!-- 月统计 ---监测报告 -->
      <div class="line44 flex1 box box_column recordImg1" v-if="winBottom == 2" ref="print1">
        <div class="lineTop"></div>
        <div class="flex1 pdfBox" id="report">
          <pdf @loaded="loadPdfHandler" class="pdf1" v-for="i in numPages" :key="i" :src="src" :page="i"> </pdf>

          <!-- 一张 -->
          <!-- <pdf ref="pdf" :src="src" @num-pages="numPages = $event"
              @page-loaded="currentPdfPage = $event" :page="currentPdfPage"></pdf> -->
        </div>
      </div>

      <!-- <iframe id="printIframe" :src="iframesrc" frameborder="0" style="display:none"></iframe> -->
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import moment from "moment";
import qs from "qs";
import CommonPagination from "@/components/Pagination";

import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";

export default {
  name: "report",
  components: {
    CommonPagination,
    pdf,
  },
  data() {
    return {
      noDataState: false,
      active: "1",
      nav: [
        { id: "1", name: "日统计" },
        { id: "2", name: "月统计" },
      ],
      time: "", //时间选择
      yuetime: "", //月选择
      //  模板选择
      // Template: [
      //   { id: "0", name: "监测统计" },
      //   { id: "1", name: "监测报表-环境类" },
      //   { id: "2", name: "监测报表-结构类" },
      //   { id: "3", name: "监测报表-荷载类" }
      // ],
      Template: [],
      Template1: [
        // { id: "0", name: "监测统计" },
        // { id: "1", name: "监测报告" }
      ],

      tempActive: "0",
      // 桥梁选择
      qiaoList: [
        { id: "1", name: "桥梁一" },
        { id: "2", name: "桥梁二" },
        { id: "3", name: "桥梁三" },
        { id: "4", name: "桥梁四" },
      ],
      qiaoActive: "1",

      list1: [
        { id: "1", name: "名称" },
        { id: "1", name: "名称" },
        { id: "1", name: "名称" },
        { id: "1", name: "名称" },
      ],
      list: [
        {
          name: "项目一",
          data: [
            [
              { id: "1", name: "分类一" },
              { id: "1", name: "分类一" },
              { id: "1", name: "分类一" },
            ],
            [
              { id: "1", name: "分类二" },
              { id: "1", name: "分类二" },
              { id: "1", name: "分类二" },
            ],
            [
              { id: "1", name: "分类三" },
              { id: "1", name: "分类二" },
              { id: "1", name: "分类三" },
            ],
          ],
        },
        {
          name: "项目二",
          data: [
            [
              { id: "1", name: "分类一" },
              { id: "1", name: "分类一" },
              { id: "1", name: "分类一" },
            ],
            [
              { id: "1", name: "分类二" },
              { id: "1", name: "分类二" },
              { id: "1", name: "分类二" },
            ],
            [
              { id: "1", name: "分类三" },
              { id: "1", name: "分类二" },
              { id: "1", name: "分类三" },
            ],
          ],
        },
      ],
      ruleForm: {
        zonglan1: "",
        zonglan2: "",
        xiaoying1: "",
        xiaoying2: "",
        huanjing1: "",
        huanjing2: "",
        hezai1: "",
        hezai2: "",
        bianhua1: "",
        bianhua2: "",
      },
      rules: [],
      reportName: "",
      reportTime: "",

      listLength: [
        {
          id: "1",
          name: "载荷类监测",
          list: [
            { id: "1", name: "风速1", list: { max: "6666", min: "7777" } },
            { id: "1", name: "风速1", list: { max: "61111", min: "7111" } },
          ],
        },
      ],

      // 报表弹框
      win: "0",
      // 弹框的数据
      reportData: [
        { id: "1", name: "名称", time: "2018" },
        { id: "1", name: "名称", time: "2018" },
        { id: "1", name: "名称", time: "2018" },
        { id: "1", name: "名称", time: "2018" },
        { id: "1", name: "名称", time: "2018" },
        { id: "1", name: "名称", time: "2018" },
        { id: "1", name: "名称", time: "2018" },
        { id: "1", name: "名称", time: "2018" },
        { id: "1", name: "名称", time: "2018" },
      ],
      huanjing: "",
      xiaoying: "",
      hezai: "",
      bianhua: "",

      winBottom: "0",

      // 分页
      total: 0,
      currentPage: 1,

      // 下载
      reportId: "", //报表id

      src: "", //pdf 报告的id
      srcId: "", // pdfid
      numPages: 1,
      currentPdfPage: 1,
      pagePdfCount: 0,

      //   {'name':'项目二','data':[{'id':'1','name':'分类一'},{'id':'1','name':'分类二'},{'id':'1','name':'分类三'},{'id':'1','name':'分类四'}]},
    };
  },
  //创建
  created() {
    console.log("data", this.list);
    this.active = this.$route.query.active || "1";

    this.setBreadcrumb(this.nav[this.active - 1]);
  },

  //安装
  mounted() {
    //   获取当前时间
    var day = new Date().getTime();
    var day1 = day - 24 * 60 * 60 * 1000;
    this.time = moment(day1).format("YYYY-MM-DD HH:mm:ss");

    var yuetime = moment(day).format("YYYY-MM-DD");

    console.log("yue", yuetime);

    this.$nextTick(() => {
      this.$forceUpdate();
    });
    this.projectId = sessionStorage.getItem("projectId");

    // 获取上个月
    this.yuetime = this.getPreMonth(yuetime) + " 00:00:00";
    this.lookReport(); //默认打开报表
    console.log("up", this.yuetime);
  },

  //方法
  methods: {
    printData() {
      console.log("打印");
      // this.$print(this.$refs.print1); // 使用
      // this.$refs.pdf.print()
      // this.$refs.pdf.print(100,[1,2])
      document.getElementById("printIframe").contentWindow.print();
      console.log("打印1");
    },
    changePage(page) {
      this.currentPage = page;
    },
    loadPdfHandler() {
      console.log("PDF加载完成啦");
    },
    //   获取上个月
    getPreMonth(date) {
      var arr = date.split("-");
      var year = arr[0]; //获取当前日期的年份
      var month = arr[1]; //获取当前日期的月份
      var day = arr[2]; //获取当前日期的日
      var days = new Date(year, month, 0);
      days = days.getDate(); //获取当前日期中月的天数
      var year2 = year;
      var month2 = parseInt(month) - 1;
      if (month2 == 0) {
        year2 = parseInt(year2) - 1;
        month2 = 12;
      }
      var day2 = day;
      var days2 = new Date(year2, month2, 0);
      days2 = days2.getDate();
      if (day2 > days2) {
        day2 = days2;
      }
      if (month2 < 10) {
        month2 = "0" + month2;
      }
      var t2 = year2 + "-" + month2 + "-" + day2;
      return t2;
    },

    // 关闭报表弹框
    closeBtn() {
      this.win = 0;
    },
    // 打开历史报表
    getWin() {
      this.win = 1;
      this.reportList();
    },
    // 下载
    xiazai() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      // 月统计, 报表

      // if(this.active == 2 && this.tempActive == 1){
      //    this.$axios.get(`${this.baseURL}report/final/download/${this.srcId}`).then((res) => {
      //       console.log("监测报告地址", res);
      //       var src = res.data.data

      //        var a = document.createElement('a')
      //       a.href = `src`
      //       const agent = window.navigator
      //       if (agent.userAgent.match(/QQ/i)) {
      //           a.target = '_blank'
      //       }
      //       a.click()
      //     });
      //   return;
      // }

      this.$axios
        .get(`${this.baseURL}report/download/${this.reportId}`, { responseType: "blob" })
        .then((res) => {
          console.log("数据导出", res);

          let a = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          let objectUrl = URL.createObjectURL(blob);
          a.setAttribute("href", objectUrl);
          a.setAttribute("download", this.reportName + ".xlsx");
          a.click();
        })
        .catch((err) => {});
    },
    xiazai1() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.$axios.get(`${this.baseURL}report/final/download/${this.srcId}`).then((res) => {
        console.log("监测报告地址", res);
        var src = res.data.data;

        var a = document.createElement("a");
        a.href = src;
        const agent = window.navigator;
        if (agent.userAgent.match(/QQ/i) || (agent.mimeTypes[0] && agent.mimeTypes[0].type.match(/360/i))) {
          // a.target = '_blank'
        }
        a.click();
      });
    },

    PrintRow(index, row) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      console.log("index, row", index, row);

      this.$print(this.$refs.print); // 使用
    },

    //点击窗口详情
    getInfor(item) {
      console.log("item", item);
      this.reportId = item.id;
      this.win = 0; //关闭
      this.inforDta(this.reportId); //查找详情
    },
    setBreadcrumb(item) {
      console.log("点击三级菜单", item);
      let arr = this.$store.state.breadcrumbList;
      if (arr.length > 1) {
        arr.splice(1);
      }
      arr.push(item);
      this.$store.commit("getBreadcrumb", arr);
    },
    // 切换时间选择
    getTab(item) {
      console.log("val", item);
      this.$router.push(`?active=${item.id}`);
      this.active = item.id;
      this.setBreadcrumb(item);
      this.lookReport();
      // 每次切换选中 监测统计
      this.win = 0;
      this.tempActive = 0;
    },
    //  选择时间
    changeTime() {
      console.log("time", this.time);
      this.lookReport();
    },

    //   加减时间
    getTime(val) {
      console.log("time0", this.time);

      var day = new Date(this.time);
      var dayTime = day.getTime();
      if (val == "1") {
        var time = dayTime - 1 * 24 * 60 * 60 * 1000;
      } else {
        var time = dayTime + 1 * 24 * 60 * 60 * 1000;
      }
      this.time = moment(time).format("YYYY-MM-DD HH:mm:ss");
      console.log("time", this.time);
      this.lookReport();
    },

    changeTimeYue() {
      console.log("yue", this.yuetime);
      // 选中月 加 报表
      if (this.active == 2 && this.tempActive == 1) {
        this.reportPDF();
        return;
      }
      this.lookReport();
    },

    //加减月
    getTimeYue(val) {
      // let currentDate = "2019-04";
      let currentDate = this.yuetime;
      console.log("yuetime", this.yuetime);

      currentDate = new Date(currentDate); // 将日期格式转换为 Mon Apr 01 2019 08:00:00 GMT+0800 (中国标准时间)

      let lastDate = currentDate.setMonth(currentDate.getMonth() - Number(val)); // 输出日期格式为毫秒形式1551398400000
      lastDate = new Date(lastDate);
      let lastYear = lastDate.getFullYear();
      let lastMonth = this.checkMonth(lastDate.getMonth() + 1); // 因日期中的月份表示为0-11，所以要显示正确的月份，需要 + 1
      let lastDay = this.checkMonth(lastDate.getDate());

      lastDate = lastYear + "-" + lastMonth + "-" + lastDay; // "2019-03"

      this.yuetime = lastDate + " 00:00:00";

      console.log("lastDate", lastDate);
      this.changeTimeYue();
    },
    checkMonth(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    // 切换 统计,报表
    getTemp(val) {
      console.log("切换 统计,报表", val);
      this.tempActive = val;
      // 监测统计
      if (val == 0) {
        this.lookReport();
      }
      // 监测报告
      if (val == 1) {
        // 获取pdf-id
        this.reportPDF();
      }
    },

    // 分页事件
    handleCurrentChange(val) {
      this.currentPage = val;
      this.reportList();
    },

    //----------------------------------  请求 数据 --------------------------------------
    // 按日期，类型查询
    lookReport() {
      if (this.active == 1) {
        var time = this.time;
      } else {
        var time = this.yuetime;
      }

      var data = {
        projectId: this.projectId,
        reportGroup: this.tempActive, // 0-总表 1-环境类 2-效应类 3-荷载类
        reportTime: time, //2021-12-26 00:00:00
        reportType: this.active, //1日  2月
      };
      console.log("报表", data);

      this.$axios
        .post(`${this.baseURL}report/detail`, data)
        .then((res) => {
          console.log("生成报表", res.data.data);
          if (res.data.data.dataList == null) {
            this.noDataState = true;
            // this.$message.error("无数据");
            return;
          } else {
            this.noDataState = false;
          }
          this.reportId = res.data.data.reportId; //报表id
          console.log("生成报表", res.data.data.dataList);
          //  1-环境类监测 2-效应类监测 3-荷载类监测
          var nameData = res.data.data;

          this.ruleForm.xiaoying1 = nameData.effectItemCount;
          this.ruleForm.xiaoying2 = nameData.effectTargetCount;

          this.ruleForm.huanjing1 = nameData.environmentItemCount;
          this.ruleForm.huanjing2 = nameData.environmentTargetCount;

          this.ruleForm.hezai1 = nameData.loadItemCount;
          this.ruleForm.hezai2 = nameData.loadTargetCount;

          this.ruleForm.zonglan1 = nameData.totalItemCount;
          this.ruleForm.zonglan2 = nameData.totalTargetCount;

          this.ruleForm.bianhua1 = nameData.changeItemCount;
          this.ruleForm.bianhua2 = nameData.changeTargetCount;

          this.reportName = nameData.reportName;
          this.reportTime = nameData.reportTime;

          this.winBottom = 1;
          var data = res.data.data.dataList;
          data.forEach((item, index) => {
            if (item.dataGroup == 1) {
              this.huanjing = item;
            }
            if (item.dataGroup == 2) {
              this.xiaoying = item;
            }
            if (item.dataGroup == 3) {
              this.hezai = item;
            }
            if (item.dataGroup == 4) {
              this.bianhua = item;
            }
          });
        })
        .catch((err) => {});
    },

    // 查询历史表单列表
    reportList() {
      // 月报,并且选中报表
      if (this.active == 2 && this.tempActive == 1) {
        this.$axios.get(`${this.baseURL}report/final/page?current=${this.currentPage}&size=20&projectId=${this.projectId}`).then((res) => {
          console.log("历史列表", res);
          this.reportData = res.data.data.records;
          this.total = res.data.data.total;
        });
        return;
      }

      this.$axios.get(`${this.baseURL}report/page?current=${this.currentPage}&size=20&projectId=${this.projectId}&typeId=${this.active}`).then((res) => {
        console.log("历史列表", res);
        this.reportData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },

    // 查找详情
    inforDta(id) {
      // 点击历史窗口 月, 和, 报表
      if (this.active == 2 && this.tempActive == 1) {
        this.srcId = id;
        this.pdfSite();
        return;
      }
      this.$axios.get(`${this.baseURL}report/detail/${id}`).then((res) => {
        var nameData = res.data.data;

        this.ruleForm.xiaoying1 = nameData.effectItemCount;
        this.ruleForm.xiaoying2 = nameData.effectTargetCount;

        this.ruleForm.huanjing1 = nameData.environmentItemCount;
        this.ruleForm.huanjing2 = nameData.environmentTargetCount;

        this.ruleForm.hezai1 = nameData.loadItemCount;
        this.ruleForm.hezai2 = nameData.loadTargetCount;

        this.ruleForm.zonglan1 = nameData.totalItemCount;
        this.ruleForm.zonglan2 = nameData.totalTargetCount;

        this.ruleForm.bianhua1 = nameData.changeItemCount;
        this.ruleForm.bianhua2 = nameData.changeTargetCount;

        this.reportName = nameData.reportName;
        this.reportTime = nameData.reportTime;

        this.winBottom = 1;
        var data = res.data.data.dataList;
        if (res.data.data.dataList == null) {
          this.noDataState = true;
          // this.$message.error("无数据");
          return;
        } else {
          this.noDataState = false;
        }
        data.forEach((item, index) => {
          if (item.dataGroup == 1) {
            this.huanjing = item;
          }
          if (item.dataGroup == 2) {
            this.xiaoying = item;
          }
          if (item.dataGroup == 3) {
            this.hezai = item;
          }
          if (item.dataGroup == 4) {
            this.bianhua = item;
          }
        });
      });
    },

    // 按月份查询报表
    reportPDF() {
      var data = {
        projectId: this.projectId,
        reportTime: this.yuetime,
        reportType: 2,
      };
      console.log("提交", data);

      this.$axios.post(`${this.baseURL}report/final/detail`, data).then((res) => {
        console.log("监测报告id", res);
        this.srcId = res.data.data.reportId;
        if (this.srcId == null) {
          this.$message.error("没有报表");
          return;
        }
        this.pdfSite();
      });
    },
    // 获取 pdf 地址
    pdfSite() {
      this.$axios.get(`${this.baseURL}report/final/download/${this.srcId}`).then((res) => {
        console.log("监测报告地址", res);
        var src = res.data.data;
        // this.iframesrc = src
        // 展示pdf
        console.log("src", src);
        this.pdfTask(src);
      });
    },
    // pdf展示
    pdfTask(src) {
      this.winBottom = 2;
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.src = pdf.createLoadingTask(src);
      this.src.promise.then((pdf) => {
        this.numPages = pdf.numPages;
        console.log("numPages", this.numPages);
        setTimeout(() => {
          this.loadingPage.close();
        }, 500);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.empty {
  width: 120px;
  margin: calc(100vh - 750px) auto 0;
  font-size: 16px;
  text-align: center;
  img {
    width: 120px;
    margin-bottom: 17px;
  }
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.zhw-content {
  color: #fff;
  border-radius: 0 !important;
  height: 42px !important;
  line-height: 42px !important;
  border: 1px solid #3b68a97a !important;
  background: #112149 !important;
  padding: 0 15px;
  box-sizing: border-box;
}

.border-table {
  border-collapse: collapse;
  border: none;
  tr {
    width: 100%;
  }
}
.border-table td {
  border: solid #ccc 1px;
}

.ulTab {
  .fu {
    position: relative;
  }
  li {
    position: relative;
    cursor: pointer;
  }
  .tiao {
    position: absolute;
    top: 30px;
    width: 100%;
    height: 8px;
    background: url("../../assets/images/report/juxing.png");
    background-size: 100% 100%;
    // background-position: 50px 0;
  }
  .shu {
    margin: 0 30px;
    width: 1px;
    height: 20px;
    background: #1e2f5c;
  }
}

::v-deep .timeUi {
  .el-input--prefix .el-input__inner {
    padding-left: 60px;
  }
  .el-input--suffix .el-input__inner {
    padding-right: 60px;
  }
  .el-input__icon {
    display: none;
  }
}

.btnFu {
  position: relative;
  .leftBtn {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 4px;
    height: 9px;
    background: url("../../assets/images/report/leftBtn.png");
    background-size: 100% 100%;
    cursor: pointer;
    z-index: 2;
  }
  .rightBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 4px;
    height: 9px;
    background: url("../../assets/images/report/rightBtn.png");
    background-size: 100% 100%;
    cursor: pointer;
    z-index: 2;
  }
}

.bottom1 {
  padding-top: 24px;
}
.noborder {
  background: none;
  border: none;
  padding: 24px 0px 24px 0px;
}

.flex-center {
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.use {
  padding: 5px 20px;
  background: #2667db;
  border-radius: 16px;
  border: 1px solid #233361;
  // line-height: 32px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.useSite {
  margin-left: 56px;
  margin-right: 16px;
}

.main {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
}

.line1 {
  margin-bottom: 16px;
}
.line2 {
}
.line3 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.history {
  margin-left: 16px;
  font-size: 14px;
  // width: 145px;
  // height: 32px;
  border-radius: 16px;
  padding: 5px 20px;
  border: 1px solid #233361;
  cursor: pointer;
}

.line4 {
  width: 100%;
  height: 100%;
  .lineTop {
    width: 100%;
    height: 32px;
    background: linear-gradient(135deg, #002f83 0%, rgba(0, 19, 59, 0.5) 100%);
    border-radius: 8px 8px 2px 2px;
    border: 1px solid;
    border-image: linear-gradient(138deg, rgba(17, 102, 255, 1), rgba(0, 0, 0, 0.5), rgba(17, 102, 255, 1)) 1 1;
  }
  .lineBottom {
    padding-top: 34px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    // padding-bottom: 20px;
    width: 100%;
    // height: 100%;
    background: #04153f;
    border-radius: 2px;
    border: 1px solid #0c3380;
  }
}

.line44 {
  width: 100%;
  // height: 100%;
  overflow-y: hidden;
  .lineTop {
    // width: 100%;
    // height: 32px;
    // background: linear-gradient(135deg, #002f83 0%, rgba(0, 19, 59, 0.5) 100%);
    // border-radius: 8px 8px 2px 2px;
    // border: 1px solid;
    // border-image: linear-gradient(138deg, rgba(17, 102, 255, 1), rgba(0, 0, 0, 0.5), rgba(17, 102, 255, 1)) 1 1;
  }
  .lineBottom {
    padding-top: 34px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    // padding-bottom: 20px;
    width: 100%;
    // height: 100%;
    background: #04153f;
    border-radius: 2px;
    border: 1px solid;
    border-image: linear-gradient(119deg, rgba(17, 102, 255, 1), rgba(0, 50, 175, 0.95)) 1 1;
  }
}

::v-deep .formBox {
  div.el-input .el-input__inner {
    border-radius: 0px !important;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
  .sp {
    font-size: 14px;
    width: 212px;
    height: 42px;
    text-align: center;
    border: 1px solid #3b68a97a;
    border-right: none;
    background: #0c2153;
    color: #92b7f9;
  }

  .el-form {
    .el-form-item__label {
      text-align: center;
      font-size: 14px;
      padding: 0;
      color: #96d5ff;
      height: 40px;
      line-height: 40px;
      border: 1px solid #215795 !important;
      background: #0c265a;
    }
    .el-input {
      // height: 40px;
      border-radius: 0 !important;
    }
    .el-input__inner {
      color: #fff;
      border-radius: 0 !important;
      height: 42px !important;
      line-height: 42px !important;
      border: 1px solid #215795 !important;
      background: #112149 !important;
      background-color: transparent;
    }
  }
}

.width25 {
  width: 25%;
}
.width20 {
  width: 20%;
}
.noLeft {
  border: 1px solid #ccc;
  border-left: 0;
}

.table_1 {
  width: 100%;
  border-collapse: collapse;
}
.table_1 th,
.table_1 td {
  border: 1px solid rgba(204, 218, 255, 0.3);
  text-align: center;
}

.setP {
  td {
    text-align: center;
    /* padding-left: 20px; */
    padding-top: 10px;
    padding-bottom: 10px;
    // font-weight: 600 !important;
  }

  th {
    text-align: center;
    /* padding-left: 20px; */
    padding-top: 10px;
    padding-bottom: 10px;
    background: #12254d;
  }
}

.setPLi:nth-child(odd) {
  background: #0c265a;
}
.setPLi:nth-child(even) {
  background: #0c2052;
}
.setbg1 {
  background-color: #0c265a;
}
.setbg0 {
  background-color: #0c2052;
}
// 报表弹框
::v-deep .reportWin {
  width: 1146px;
  background-size: 100% 100%;
  background-image: linear-gradient(0deg, #0a173d 0%, #124286 100%);
  border-radius: 4px;
  position: absolute;
  left: 50%;
  margin-left: -557px;
  top: 20%;
  z-index: 3;
  padding: 13px 20px;
  .close {
    position: absolute;
    right: 20px;
    top: 14px;
    width: 24px;
    height: 24px;
    background: url("../../assets/images/report/winClose.png");
    background-size: 100% 100%;
    z-index: 3;
  }
  .titleName {
    padding-bottom: 10px;
    border-bottom: 1px solid #7ca9df99;
    margin-bottom: 10px;
  }
  .winB {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 52px;
    width: 100%;
    background: url("../../assets/images/report/winMb.png");
    background-size: 100% 100%;
    z-index: 3;
  }
  .reportBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border: 1px solid #1f52b9;
    padding: 20px;
    .report-item {
      width: 200px;
      margin-right: 16px;
      background: url("../../assets/images/report/libg.png");
      background-size: 100% 100%;
      padding: 15px;
      margin-bottom: 16px;
    }
    .report-item :hover {
      cursor: pointer;
      background: url("../../assets/images/report/libgAc.png");
    }
    .report-item:nth-child(5n) {
      margin-right: 0;
    }
    li {
      width: 200px;
      margin-right: 16px;
      background: url("../../assets/images/report/libg.png");
      background-size: 100% 100%;
      // 换行
      display: inline;
      // line-height: 40px;
      float: left;
      padding: 15px;
      margin-bottom: 16px;
    }
    li:nth-child(5n) {
      margin-right: 0;
    }
    li:hover {
      cursor: pointer;
      background: url("../../assets/images/report/libgAc.png");
      background-size: 100% 100%;
    }
  }
}

.btnStyle {
  // width: 96px;
  // height: 32px;
  padding: 5px 20px;
  border-radius: 16px;
  border: 1px solid #2667db;
  text-align: center;
  // line-height: 32px;
  margin-left: 16px;
  font-size: 14px;
}

.recordImg {
  width: 100%;
}
.recordImg1 {
  width: 100%;
  margin: 0 auto;
}

.pdfBox {
  width: 75%;
  margin: 0 auto;
  // height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  // position: absolute;
  // left: 50%;
  // margin-left: -300px;
  margin-top: 20px;
  // background:linear-gradient(to top, #0a173d, #124386);
}

// @media print {
// html {
//     zoom: 90% !important;
// }
// }
::v-deep .el-dialog__header {
  background-image: linear-gradient(94deg, #124183 0%, #123682 100%);
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #7ca9df99;
}
::v-deep .el-dialog__headerbtn {
  width: 24px;
  height: 24px;
  background: rgba(204, 218, 255, 0.2);
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  top: 14px;
}
::v-deep .el-icon-close:before {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
}

::v-deep .el-dialog__body {
  background-image: linear-gradient(0deg, #0a173d 0%, #124286 100%);
  border-radius: 4px;
}
</style>
